import '../styles/templates/about.scss'

import React from 'react'

import { PAGE_NAME } from '../../config/cms'
import { getFilePath } from '../../src/utils/getFilePath'
import aboutImg from '../../static/assets/svg/aboutImg.svg'
import genericDeviceIcon from '../../static/assets/svg/generic-device-icon.svg'
import screenIcon from '../../static/assets/svg/screen-icon.svg'
import statisticsIcon from '../../static/assets/svg/statistics-icon.svg'
import { Carousel } from '../components/carousel'
import { ArrowIndicator } from '../components/icons/arrow-indicator'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { usePageData } from '../hooks/cms/use-page'
import { PageComponentFactory } from '../utils/pages'

const ProductDetailsPage = PageComponentFactory(() => {
  const {
    landingSection,
    companyDescriptionSection,
    companyHistorySection,
    carousel,
  } = usePageData(PAGE_NAME.About)

  return (
    <Layout page={PAGE_NAME.About} pathname={`/about/`}>
      <main className="about-page">
        <section className="about-page__landing-section">
          <div className="container">
            <div className="about-page__grid-container">
              <div className="about-page__content">
                <PageHeading>{landingSection.heading}</PageHeading>
                <PageSubheading>{landingSection.caption}</PageSubheading>
              </div>
              <section className="about-page__image-container section">
                <img src={aboutImg} className="about-page__image" />
              </section>

              <div className="about-page__divider" />
              <ArrowIndicator />
            </div>
          </div>
        </section>

        <section className="about-page__history-description section">
          <div>
            <h3>{companyHistorySection.heading}</h3>
            <h4>{companyHistorySection.caption}</h4>
          </div>
          <div className="about-page__history-description-carousel">
            {carousel?.length && (
              <Carousel
                slides={carousel.map(({ image }, key) => (
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                    key={key}
                    src={getFilePath(image)}
                  />
                ))}
              />
            )}
          </div>
        </section>
        <section className="about-page__company-description section">
          <h3>{companyDescriptionSection.heading}</h3>
          <div className="about-page__company-description-divider" />
          <div className="about-page__company-description-images">
            <img src={genericDeviceIcon} />
            <img src={screenIcon} />
            <img src={statisticsIcon} />
          </div>

          <pre>
            <h4>{companyDescriptionSection.caption}</h4>
          </pre>
        </section>
      </main>
    </Layout>
  )
})

export default ProductDetailsPage
